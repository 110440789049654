footer ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: calc(4 * var(--grid-unit)) 0;
  align-items: center;
  padding: 0;

  & svg > path {
    transition: fill var(--transition);
  }

  & a {
    color: var(--white);

    &:hover {
      color: var(--light-grey);

      & svg > path {
        fill: var(--light-grey);
      }
    }
  }
}

.navigation ul {
  @media (max-width: env(--phone)) {
    flex-direction: column;
  }

  & li {
    text-align: center;
    margin: 0 calc(8 * var(--grid-unit));

    @media (max-width: env(--phone)) {
      margin: var(--grid-unit) 0;
    }
  }
}

.social {

  & ul li {
    margin: 0 calc(4 * var(--grid-unit));
  }
}

.contact {
  text-align: center;

  & p {
    color: var(--whitey);
  }

  & ul {
    @media (max-width: env(--phone)) {
        flex-direction: column;
    }
  }

  & ul li {
    margin: 0 calc(5 * var(--grid-unit));

    @media (max-width: env(--phone)) {
      margin: 0 calc(3 * var(--grid-unit));
    }

    & img {
      height: 48px;
      width: auto;

      @media (max-width: env(--phone)) {
        height: 36px;
      }
    }

    &:nth-child(2) img {
      height: 30px;

      @media (max-width: env(--phone)) {
        height: 22px;
      }
    }
  }
}
