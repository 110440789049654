.logo {
  margin: auto;
  display: block;
  width: 80%;
}

@media (max-width: env(--phone-l)) {
  .logo {
    width: 90%;
  }
}
