.wrap {
  margin: 0 auto;
  max-width: calc(360 * var(--grid-unit));
  padding: calc(14 * var(--grid-unit)) calc(20 * var(--grid-unit));
}

@media (max-width: env(--tablet)) {
  .wrap {
    padding: calc(10 * var(--grid-unit)) calc(12 * var(--grid-unit));
  }
}

@media (max-width: env(--phone)) {
  .wrap {
    padding: calc(8 * var(--grid-unit)) calc(6 * var(--grid-unit));
  }
}
