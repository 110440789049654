:root {
  --black: #000;
  --green: #68755e;
  --dark-grey: #272838;
  --mid-grey: #92939b;
  --reg-grey: #d3d4d7;
  --light-grey: #f4f4f5;
  --white: #fff;

  /* grid */
  --grid-unit: 4px;

  /* sizing (margins, paddings)*/

  /* fonts */
  --sans: "Streetvertising", sans-serif;

  --size-body: 16px;
  --size-giga: 4rem;
  --size-h1: 2rem;
  --size-h2: 1.5rem;
  --size-h3: 1.125rem;
  --size-h4: 1rem;
  --size-p: 1rem;
  --size-body-s: 0.875rem;
  --size-body-xs: 0.75rem;

  --weight-regular: 400;
  --weight-medium: 500;
  --weight-bold: 700;

  /* font: font-style font-variant font-weight font-size/line-height font-family; */
  --style-variant: normal normal;
  --line-height: 150%;
  --font-giga: var(--style-variant) var(--weight-bold) var(--size-giga) /
    var(--line-height) var(--sans);
  --font-h1: var(--style-variant) var(--weight-bold) var(--size-h1) /
    var(--line-height) var(--sans);
  --font-h2: var(--style-variant) var(--weight-bold) var(--size-h2) /
    var(--line-height) var(--sans);
  --font-h3: var(--style-variant) var(--weight-bold) var(--size-h3) /
    var(--line-height) var(--sans);
  --font-h4: var(--style-variant) var(--weight-bold) var(--size-h4) /
    var(--line-height) var(--sans);
  --font-body: var(--style-variant) var(--weight-regular) var(--size-body) /
    var(--line-height) var(--sans);
  --font-p: var(--style-variant) var(--weight-regular) var(--size-p) /
    var(--line-height) var(--sans);
  --font-body-b: var(--style-variant) var(--weight-bold) var(--size-body) /
    var(--line-height) var(--sans);
  --font-body-s: var(--style-variant) var(--weight-regular) var(--size-body-s) /
    20px var(--sans);
  --font-body-s-em: var(--style-variant) var(--weight-medium) var(--size-body-s) /
    20px var(--sans);
  --font-link: var(--style-variant) var(--weight-bold) var(--size-body-xs);

  /* custom */
  --header-height: calc(20 * var(--grid-unit));
  --transition: 0.3s ease-in-out;

  /* media queries */
  @media (max-width: env(--phone-l)) {
    --size-body: 14px;
    --size-body-s: 1rem;
    --size-body-xs: 0.875rem;
    --size-h2: 1.3rem;
  }
}
