@import-normalize;
@import "./assets/css/variables.css";

html {
  height: 100%;
  background: var(--green);
}

body {
  color: var(--white);
  min-height: 100vh;
  min-width: 320px;
}

html,
body {
  font: var(--font-body);
}

* {
  font-family: var(--sans);
  box-sizing: border-box;
}

.giga {
  font: var(--font-giga);
}

h1 {
  font: var(--font-h1);
  margin-top: 0;
  margin-bottom: calc(4 * var(--grid-unit));
}

h2 {
  font: var(--font-h2);
  margin-top: 0;
  margin-bottom: calc(4 * var(--grid-unit));
}

h3 {
  font: var(--font-h3);
}

h4 {
  font: var(--font-h4);
}

p {
  font: var(--font-p);
}

b,
strong {
  font: var(--font-body-b);
}

em,
i {
  font: var(--font-body-s-em);
}

.body-s {
  font: var(--font-body-s);
}

a {
  font: var(--font-link);
  cursor: pointer;
  transition: color var(--transition);
  color: var(--blue);
  text-decoration: none;

  &:hover {
    color: var(--light-grey);
  }
}

*:focus {
  outline-color: var(--yellow);
}
