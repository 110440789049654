.banner {
    margin-top: calc(16 * var(--grid-unit));

    & p {
        margin: 0;
        font-size: 300%;
        text-align: center;

        @media (max-width: env(--tablet-l)) {
          font-size: 150%;
        }

        @media (max-width: env(--phone-l)) {
          font-size: 100%;
        }
    }
}
